<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="消息类型" prop="messageType">
          <el-select
            v-model="params.messageType"
            placeholder="请选择状态"
            clearable
            filterable
          >
            <el-option
              v-for="item in remindList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select
            v-model="params.state"
            placeholder="请选择状态"
            clearable
            filterable
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <template slot="headerRight">
      <el-button
        :disabled="!selections.length"
        @click="haveRead(false)"
        type="primary"
        >标记已读</el-button
      >
      <el-button @click="haveRead(true)" type="primary">全部标记已读</el-button>
    </template>

    <div class="table-wrap">
      <el-table
        :data="dataList"
        border
        max-height="650"
        size="small"
        v-loading="loading"
        @selection-change="selectHandle"
        style="width: 100%"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="content" label="内容">
          <template slot-scope="{ row }">
            <div>
              {{ row.content }}
              <el-button
                type="text"
                size="mini"
                @click="toDetail(row.messageType, row.orderId, row.id)"
                >{{ row.messageType == 1 ? '去查看' : '去处理' }}</el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          prop="messageTime"
          label="时间"
        ></el-table-column>
        <el-table-column width="80" label="状态">
          <template slot-scope="{ row }">
            <span>{{ +row.state === 0 ? '未读' : '已读' }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      currentItem: null,
      dataList: [],
      currentPage: 1,
      pageSize: 20,
      totalCount: 0,
      remindList: [
        {
          label: '新订单通知',
          value: 1
        },
        {
          label: '售后订单提醒',
          value: 2
        }
      ],
      statusList: [
        {
          label: '未读',
          value: 0
        },
        {
          label: '已读',
          value: 1
        }
      ],
      loading: false,
      selections: [],
      params: {}
    }
  },
  computed: {
    userType() {
      return localStorage.getItem('userType')
    }
  },
  methods: {
    // 获取数据列表
    async getData() {
      this.loading = true

      try {
        const res = await this.$api.notification.listMessage({
          currentPage: this.params.page,
          pageSize: this.params.limit,
          state: this.params.state,
          messageType: this.params.messageType
        })

        this.dataList = res.page.list
        this.totalCount = res.page.total
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    // 选择（多选）
    selectHandle(val) {
      this.selections = val
    },
    async toDetail(messageType, orderId, id) {
      try {
        await this.$api.notification.partRead({
          ids: [id]
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.$router.push({
          name:
            messageType == 1
              ? this.userType == 'SUPPLIER'
                ? 'orderList'
                : 'storeProcurementOrdersMerge'
              : 'afterOrderList',
          query: {
            orderId
          }
        })
      }
    },
    async haveRead(all) {
      this.loading = true
      try {
        all
          ? await this.$api.notification.allRead()
          : await this.$api.notification.partRead({
              ids: this.selections.map(_ => _.id)
            })

        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.getData()
        this.$store.dispatch(`common/SET_MESSAGE`)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
